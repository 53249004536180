/* example file src/analytics.js */
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import mixpanelPlugin from '@analytics/mixpanel'

console.log(process?.env?.REACT_APP_GA_MEASUREMENT_ID)

const analytics = Analytics({
  app: 'tracktaco',
  plugins: [
    googleAnalytics({
        // trackingId: process?.env?.REACT_APP_GA_MEASUREMENT_ID,
        measurementIds: [process?.env?.REACT_APP_GA_MEASUREMENT_ID]
    }),
    mixpanelPlugin({
    token: process?.env?.REACT_APP_MIXPANEL_ID,
    })
  ]
})

/* export the instance for usage in your app */
export default analytics

analytics.on('track', ({ payload }) => {
  console.log('analytics: track', payload)
})
analytics.on('page', ({ payload }) => {
  console.log('analytics: page.', payload)
})
analytics.on('identify', ({ payload }) => {
  console.log('analytics: identify.', payload)
})
analytics.on('reset', ({ payload }) => {
  console.log('analytics: reset.', payload)
})
