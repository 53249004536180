/* eslint-disable class-methods-use-this */
import type { LambdaPaymentIntentInput, LambdaPaymentIntentResult } from '../../../cdk/src/lambda/frontend-api/payment-intent';
import type { LambdaValidateCouponInput,LambdaValidateCouponResult } from '../../../cdk/src/lambda/frontend-api/validate-coupon';
import type { LambdaCreateCoinbaseChargeInput, LambdaCreateCoinbaseChargeResult } from '../../../cdk/src/lambda/frontend-api/create-coinbase-charge';
// import type { LambdaCreateSquareChargeInput, LambdaCreateSquareChargeResult } from '../../../cdk/src/lambda/frontend-api/square-payment';
import type { GetTrackingNrStrictInputType, GetTrackingNrStrictResultType } from '../../../cdk/src/lambda/frontend-api/get-tracking-nr-us';
import type { GetTrackingNrAvailabilityCountInputType, GetTrackingNrAvailabilityCountResultType } from '../../../cdk/src/lambda/frontend-api/get-tracking-nr-availability-us';
import type { GetTrackingNrIntlInputType, GetTrackingNrIntlResultType } from '../../../cdk/src/lambda/frontend-api/get-tracking-nr-intl';
import type { ResetPasswordInputType, ResetPasswordResultType } from '../../../cdk/src/lambda/frontend-api/reset-password';
import type { RegisterInputType, RegisterResultType } from '../../../cdk/src/lambda/frontend-api/register';
import type { GenerateApiKeyInputType, GenerateApiKeyResultType } from '../../../cdk/src/lambda/frontend-api/generate-apikey';
import type { LambdaGetLastPurchasedCreditPackageInput, LambdaGetLastPurchasedCreditPackageResult } from '../../../cdk/src/lambda/frontend-api/get-last-purchased-credit-package';
import type { LambdaAutoPurchaseCreditsInput, LambdaAutoPurchaseCreditsResult } from '../../../cdk/src/lambda/frontend-api/auto-purchase-credits';
import type { GetTrackingNrsInputType,GetTrackingNrsResultType } from '../../../cdk/src/lambda/frontend-api/find-trackingnrs';
import type { CheckAndBuyTrackingNrInputType,CheckAndBuyTrackingNrResultType } from '../../../cdk/src/lambda/frontend-api/check-and-buy-tn';
import type { ExportHistoryCsvInput, ExportHistoryCsvResult } from '../../../cdk/src/lambda/frontend-api/export-history-csv';
// import dbInstance from './database';
import { LoginInputType, LoginResultType } from '../../../cdk/src/lambda/frontend-api/login';
import { GetUserInputType, GetUserResultType } from '../../../cdk/src/lambda/frontend-api/get-user';
import { LogoutInputType, LogoutResultType } from '../../../cdk/src/lambda/frontend-api/logout';
import { GetHistoryInput, GetHistoryResult } from '../../../cdk/src/lambda/frontend-api/get-history';
import { ChangePasswordInputType, ChangePasswordResultType } from '../../../cdk/src/lambda/frontend-api/change-password';

/**
 * API instance, wraps frontend API requests.
 * Authentication is done via JWT cookie so it doesn't need any frontend code.
 */
class API {
  private async doRequest<I, R>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', body?: I,abortSignal?:AbortSignal): Promise<R> {
    try {
      const url = process.env.REACT_APP_FRONTEND_API_URL + path.replace(/^\//, '');
      // const jwt = localStorage.getItem('jwt');
      const sessionId = localStorage.getItem('session') || undefined;
      const headers = {
        // 'Content-Type': 'application/json',
        ...(sessionId?{'Authorization': sessionId}:{}),
      };
      const response = await fetch(url, { method, body: body ? JSON.stringify(body) : undefined, headers,signal:abortSignal });
      const result = await response.json();
      if(response.status === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('session');
        // reload page to clear all state
        window.location.reload();
      }
      if (response.status > 299) {
        throw new Error((result && result.error) || `statuscode ${response.status}, message: ${JSON.stringify(result)}`);
      }
      return result as R;
    } catch (err) {
      console.log('api error', err);
      throw err;
    }
  }

  public async resetPassword(inp: ResetPasswordInputType) {
    return this.doRequest<ResetPasswordInputType, ResetPasswordResultType>('reset-password', 'POST', inp);
  }
  public async changePassword(inp: ChangePasswordInputType) {
    return this.doRequest<ChangePasswordInputType, ChangePasswordResultType>('change-password', 'POST', inp);
  }

  // public async login(email: string, password: string) {
  //   const result = await this.doRequest<LoginInputType, LoginResultType>('login', 'POST', { email, password });
  //   dbInstance.setToken(result.user.token);
  //   localStorage.setItem('jwt', result.jwt);
  //   return result.user;
  // }

  public async register(inp: RegisterInputType) {
    const result = await this.doRequest<RegisterInputType, RegisterResultType>('register', 'POST', inp);
    localStorage.setItem('user', JSON.stringify(result.user));
    localStorage.setItem('session', result.sessionId);
    return result;
  }

  public async generateApiKey(inp: GenerateApiKeyInputType) {
    return this.doRequest<GenerateApiKeyInputType, GenerateApiKeyResultType>('generate-apikey', 'POST', inp);
  }

  public async validateCoupon(inp: LambdaValidateCouponInput) {
    return this.doRequest<LambdaValidateCouponInput,LambdaValidateCouponResult>('validate-coupon', 'POST', inp);
  }
  public async getOrUpdatePaymentIntent(inp: LambdaPaymentIntentInput) {
    return this.doRequest<LambdaPaymentIntentInput, LambdaPaymentIntentResult>('payment-intent', 'POST', inp);
  }
  public async createCoinbaseCharge(inp: LambdaCreateCoinbaseChargeInput) {
    return this.doRequest<LambdaCreateCoinbaseChargeInput, LambdaCreateCoinbaseChargeResult>('create-coinbase-charge', 'POST', inp);
  }
  // public async createSquareCharge(inp: LambdaCreateSquareChargeInput) {
  //   return this.doRequest<LambdaCreateSquareChargeInput, LambdaCreateSquareChargeResult>('square-payment', 'POST', inp);
  // }

  public async getLastPurchasedCreditPackage(inp: LambdaGetLastPurchasedCreditPackageInput) {
    return this.doRequest<LambdaGetLastPurchasedCreditPackageInput, LambdaGetLastPurchasedCreditPackageResult>('get-last-purchased-credit-package', 'POST', inp);
  }

  public async autoPurchasePackageCredits(inp: LambdaAutoPurchaseCreditsInput) {
    return this.doRequest<LambdaAutoPurchaseCreditsInput, LambdaAutoPurchaseCreditsResult>('auto-purchase-credits', 'POST', inp);
  }

  public async getTrackingNrUSA(inp: GetTrackingNrStrictInputType) {
    return this.doRequest<GetTrackingNrStrictInputType, GetTrackingNrStrictResultType>('get-trackingnr-strict', 'POST', inp);
  }

  public async queryTrackingNrs(inp: GetTrackingNrsInputType,cancelSignal?:AbortSignal) {
    return this.doRequest<GetTrackingNrsInputType, GetTrackingNrsResultType>('find-trackingnrs', 'POST', inp,cancelSignal);
  }

  public async checkAndBuyTrackingNr(inp: CheckAndBuyTrackingNrInputType) {
    return this.doRequest<CheckAndBuyTrackingNrInputType,CheckAndBuyTrackingNrResultType>('check-and-buy-tn', 'POST', inp);
  }

  public async getTrackingNrAvailabilityUSA(inp: GetTrackingNrAvailabilityCountInputType) {
    return this.doRequest<GetTrackingNrAvailabilityCountInputType, GetTrackingNrAvailabilityCountResultType>('get-trackingnr-availability-us', 'POST', inp);
  }

  public async getTrackingNrIntl(inp: GetTrackingNrIntlInputType) {
    return this.doRequest<GetTrackingNrIntlInputType, GetTrackingNrIntlResultType>('get-trackingnr-intl', 'POST', inp);
  }

  public async getHistory(inp: GetHistoryInput) {
    return this.doRequest<GetHistoryInput, GetHistoryResult>('get-history', 'POST', inp);
  }

  public async exportHistoryToCsv(inp: ExportHistoryCsvInput) {
    return this.doRequest<ExportHistoryCsvInput, ExportHistoryCsvResult>('export-history-csv', 'POST', inp);
  }

  public async login(inp: LoginInputType) {
    const result = await this.doRequest<LoginInputType, LoginResultType>('login', 'POST', inp);
    localStorage.setItem('user', JSON.stringify(result.user));
    localStorage.setItem('session', result.sessionId);
    return result;
  }

  public async logout(inp: LogoutInputType) {
    await this.doRequest<LogoutInputType, LogoutResultType>('logout', 'POST', inp);
    localStorage.removeItem('user');
    localStorage.removeItem('session');
  }

  public async getUser(inp: GetUserInputType) {
    const result=await this.doRequest<GetUserInputType, GetUserResultType>('get-user', 'POST', inp);
    localStorage.setItem('user', JSON.stringify(result.user));
    return result
  }
}

export const api = new API();

export default api;
