export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  // UNICORN: 'UNICORN'
};

export const originShipmentLocations = [
  { city: 'BLOOMINGTON', state: 'CA' },
  { city: 'ARCADIA', state: 'CA' },
  { city: 'SANTA FE SPRINGS', state: 'CA' },
  { city: 'ANAHEIM', state: 'CA' },
  { city: 'PHOENIX', state: 'AZ' },
  { city: 'CITY OF INDUSTRY', state: 'CA' },
  { city: 'CARSON', state: 'CA' },
  { city: 'SAN DIEGO', state: 'CA' },
  { city: 'KEASBEY', state: 'NJ' },
  { city: 'HENDERSON', state: 'NV' },
  { city: 'IRVING', state: 'TX' },
  { city: 'LOS ANGELES', state: 'CA' },
  { city: 'POOLER', state: 'GA' },
  { city: 'ELLENWOOD', state: 'GA' },
  { city: 'DAYTON', state: 'NJ' },
  { city: 'ST GEORGE', state: 'UT' },
  { city: 'HOUSTON', state: 'TX' },
  { city: 'HUTCHINS', state: 'TX' },
  { city: 'MEMPHIS', state: 'TN' },
  { city: 'MIAMI', state: 'FL' },
];
