import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/utils/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { AnalyticsProvider, useAnalytics } from 'use-analytics'

import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import analytics from 'src/analytics';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY!;
const stripePromise = loadStripe(stripePublicKey);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const queryClient = new QueryClient()

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <AnalyticsProvider instance={analytics}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                  dense
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  maxSnack={5}
                >
                  <Router history={history}>
                    <AuthProvider>
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      <CookiesNotification />
                      {/* <SettingsNotification /> */}
                      {renderRoutes(routes)}
                    </AuthProvider>
                  </Router>
                </SnackbarProvider>
              </QueryClientProvider>
            </MuiPickersUtilsProvider>
          </AnalyticsProvider>
        </StylesProvider>
      </ThemeProvider>
    </Elements>
  );
};

export default App;
