import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
// import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import { useHistory } from 'react-router';
import api from 'src/data/api';
import { DbUserWithoutPassword } from '../../../cdk/src/lib/database/db-interfaces';
import { useAnalytics } from 'use-analytics';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: DbUserWithoutPassword | null;
}

interface RegisterInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  source: string
  password: string;
  utmSource?: string;
  captchaToken?: string;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (inp: RegisterInput) => Promise<void>;
  refreshUser: () => Promise<DbUserWithoutPassword>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: DbUserWithoutPassword | null;
  };
};

type RefreshAction = {
  type: 'REFRESH';
  payload: {
    user: DbUserWithoutPassword;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: DbUserWithoutPassword;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: DbUserWithoutPassword;
  };
};

type Action =
  | InitialiseAction
  | RefreshAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'REFRESH': {
      const { user } = action.payload;

      return {
        ...state,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  refreshUser: () => Promise.resolve({} as DbUserWithoutPassword),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const analytics = useAnalytics();

  const login = async (email: string, password: string) => {
    const { user } = await api.login({ email: email.toLowerCase().trim(), password });

    analytics.identify(user.id, {
      email: user.email,
    });
    analytics.track('login', {
      email: user.email,
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        user: user,
      }
    });
    history.push('/app/account/tracking');
  };

  const refreshUser = async () => {
    const { user } = await api.getUser({});

    // analytics.identify(user.id, {
    //   email: user.email,
    // });

    dispatch({
      type: 'REFRESH',
      payload: {
        user: user,
      }
    });
    return user;
  };

  const logout = async () => {
    try { await api.logout({}); } catch (err) { console.log('error logging out', err); }
    dispatch({ type: 'LOGOUT' });

    analytics.reset();
  };

  const register = async ({
    firstName, lastName, email, password, phone, source, captchaToken
  }: RegisterInput) => {
    const { user } = await api.register({
      email: email.toLowerCase().trim(), password, phone, firstName, lastName, source, captchaToken
    });

    analytics.identify(user.id, {
      email: user.email,
    });
    analytics.track('sign_up', {
      email: user.email,
      source,
    });

    dispatch({
      type: 'REGISTER',
      payload: {
        user: user,
      }
    });

    await new Promise((resolve) => setTimeout(resolve, 100));
    // history.replace('/app/account/tracking?state=CA',{
    //   state: 'CA'
    // });
    window.location.href = '/app/account/tracking?state=CA'
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const userCache = localStorage.getItem('user');
        const user = userCache && JSON.parse(userCache);

        if (user) {

          analytics.identify(user?.id, {
            email: user?.email,
          });

          // const response = await axios.get<{ user: User; }>('/api/account/me');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        refreshUser,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
